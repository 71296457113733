<template>
  <div class="place-page">
    <h1>{{ isEdit ? 'Edit gym' : 'Add gym' }}</h1>

    <form class="card card-body bg-light" @submit.prevent="isEdit ? doPlaceAdminEdit() : doPlaceAdminAdd()">

      <div class="col-12 form-group">
        <label for="title" class="form-label">Title<span class="f-required">*</span></label>
        <input
            v-model="title"
            :class="{'is-invalid': errors?.title}"
            type="text"
            class="form-control"
            id="title"/>
        <div class="invalid-feedback">{{ errors?.title }}</div>
      </div>

      <div class="col-12 form-group">
        <label for="text" class="form-label">Description</label>
        <editor
            api-key="l8jsedboa1mnlpecbtasjhhp6vmtgty0ezf0pwoa9b5e74xt"
            :init="editorInit"
            v-model="text"
            :class="{'is-invalid': errors?.text}"
            class="form-control"
            id="text"
        />
<!--        <textarea-->
<!--            v-model="text"-->
<!--            :class="{'is-invalid': errors?.text}"-->
<!--            class="form-control"-->
<!--            rows="10"-->
<!--            id="text"></textarea>-->
<!--        <div class="invalid-feedback">{{ errors?.text }}</div>-->
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="price" class="form-label">Price<span class="f-required">*</span></label>
        <div class="input-group">
          <input
              v-model="price"
              :class="{'is-invalid': errors?.price}"
              type="text"
              class="form-control"
              id="price"/>
          <span class="input-group-text">{{ this.getCurrencySymbol() }}</span>
          <div class="invalid-feedback">{{ errors?.price }}</div>
        </div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="phone" class="form-label">Phone</label>
        <input
            v-model="phone"
            :class="{'is-invalid': errors?.phone}"
            type="text"
            class="form-control"
            autocomplete="false"
            id="phone"/>
        <div class="invalid-feedback">{{ errors?.phone }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="site" class="form-label">Website</label>
        <input
            v-model="site"
            :class="{'is-invalid': errors?.site}"
            type="text"
            class="form-control"
            autocomplete="false"
            id="site"/>
        <div class="invalid-feedback">{{ errors?.site }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="facebookUrl" class="form-label">Facebook</label>
        <input
            v-model="facebookUrl"
            :class="{'is-invalid': errors?.facebookUrl}"
            type="text"
            class="form-control"
            autocomplete="false"
            id="facebookUrl"/>
        <div class="invalid-feedback">{{ errors?.facebookUrl }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="instagramUrl" class="form-label">Instagram</label>
        <input
            v-model="instagramUrl"
            :class="{'is-invalid': errors?.instagramUrl}"
            type="text"
            class="form-control"
            autocomplete="false"
            id="instagramUrl"/>
        <div class="invalid-feedback">{{ errors?.instagramUrl }}</div>
      </div>

      <div class="col-12 form-group">
        <label for="address" class="form-label">Address<span class="f-required">*</span></label>
        <input
            v-model="address"
            :class="{'is-invalid': errors?.address}"
            type="text"
            class="form-control"
            autocomplete="false"
            id="address"/>
        <div class="invalid-feedback">{{ errors?.address }}</div>
      </div>

      <div class="col-12 form-group">
        <div id="map"></div>
        <input id="map-search" class="form-control" placeholder="Search..."/>

        <input
            v-model="lng"
            type="hidden"
            class="form-control"
            autocomplete="false"
            id="lng"/>

        <input
            v-model="lat"
            type="hidden"
            :class="{'is-invalid': errors?.lat}"
            class="form-control"
            autocomplete="false"
            id="lat"/>
        <div class="invalid-feedback">{{ errors?.lat }}</div>
      </div>

      <div class="col-12 form-group">
        <label for="workTime" class="form-label">Work time<span class="f-required">*</span></label>
        <editor
            api-key="l8jsedboa1mnlpecbtasjhhp6vmtgty0ezf0pwoa9b5e74xt"
            :init="editorInit"
            v-model="workTime"
            :class="{'is-invalid': errors?.workTime}"
            class="form-control"
            id="workTime"
        />
        <!--        <textarea-->
        <!--            v-model="workTime"-->
        <!--            :class="{'is-invalid': errors?.workTime}"-->
        <!--            class="form-control"-->
        <!--            id="workTime"-->
        <!--        />-->
        <div class="invalid-feedback">{{ errors?.workTime }}</div>
      </div>

      <div class="col-12 form-group">
        <label for="userLogin" class="form-label">Owner email<span class="f-required">*</span></label>
        <input
            v-model="userLogin"
            :class="{'is-invalid': errors?.userLogin}"
            type="text"
            class="form-control"
            autocomplete="false"
            id="userLogin"/>
        <div class="invalid-feedback">{{ errors?.userLogin }}</div>
      </div>

      <div class="col-12 form-group">
        <label for="companyId" class="form-label">Company<span class="f-required">*</span></label>
        <select
            v-model="companyId"
            :class="{'is-invalid': errors?.companyId}"
            class="form-control"
            id="companyId">
          <option value="">...</option>
          <option v-for="(item) in companyList" :key="item.id" :value="item.id">
            {{ item.title }}
          </option>
        </select>
        <div class="invalid-feedback">{{ errors?.companyId }}</div>
      </div>

      <div class="col-12 form-group">
        <label for="tags" class="form-label">Tags</label>
        <select
            multiple
            v-model="tags"
            :class="{'is-invalid': errors?.tags}"
            class="form-control"
            id="tags">
          <option v-for="(item) in placeTagList" :key="item.id" :value="item.id">
            {{ item.title }}
          </option>
        </select>
        <div class="invalid-feedback">{{ errors?.tags }}</div>
      </div>

      <div class="row">
        <div class="col-lg-3" v-for="(logoTitle, logoType) in placeLogoImageTypeList" :key="logoType">
          <div class="form-group mb-4">
            <label class="form-label">
              <a href="#" @click.prevent="doPlaceLogoImageSelect(logoType)">Add {{ logoTitle }}</a>
              <input type="file" :ref="logoType" @change="doPlaceLogoImageUpload($event, logoType)" class="d-none"/>
            </label>
            <div class="image-list" v-if="placeLogoImageList[logoType]">
              <div class="image-item">
                <img :src="this.getAppUrl(this.getPlaceImageUrl(placeLogoImageList[logoType]))" class="img-thumbnail"/>
                <div class="text-center small text-muted">
                  <a href="#"
                     @click.prevent="doPlaceLogoImageDelete(placeLogoImageList[logoType].id, logoType)">delete</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 form-group mb-4">
        <label class="form-label">
          <a href="#" @click.prevent="doPlaceImageSelect">Add image</a>
          <input type="file" ref="image" @change="doPlaceImageUpload($event)" class="d-none"/>
        </label>
        <div class="image-list" v-if="placeImageList.length > 0">
          <div class="image-item" v-for="(placeImageData) in placeImageList" :key="placeImageData.id">
            <img :src="this.getAppUrl(this.getPlaceThumbImageUrl(placeImageData))" class="img-thumbnail"/>
            <div class="text-center small text-muted">
              <a href="#" @click.prevent="doPlaceImageDelete(placeImageData.id)">delete</a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <label for="status" class="form-label">Status<span class="f-required">*</span></label>
        <select
            v-model="status"
            :class="{'is-invalid': errors?.status}"
            class="form-control"
            id="status">
          <option value="">...</option>
          <option v-for="(status, key) in placeStatusEnum.getLabels()" :key="key" :value="key">
            {{ status }}
          </option>
        </select>
        <div class="invalid-feedback">{{ errors?.status }}</div>
      </div>

      <div class="col-12 col-lg-4 form-group">
        <button class="btn btn-primary me-2" type="submit">Save</button>
        <router-link to="/place/admin" class="btn btn-outline-secondary">Cancel</router-link>
      </div>

    </form>

  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import {Loader} from '@googlemaps/js-api-loader';
import Choices from 'choices.js';
import {PlaceStatusEnum} from "@/modules/place/enums/PlaceEnum";
import PlaceMixin from '@/modules/place/mixins/PlaceMixin';
import BaseMixin from "@/modules/base/mixins/BaseMixin";
import 'choices.js/public/assets/styles/choices.min.css';

export default {

  components: {
    'editor': Editor,
  },

  mixins: [PlaceMixin, BaseMixin],

  data: () => ({
    isEdit: false,

    title: null,
    text: null,
    price: null,
    phone: null,
    site: null,
    facebookUrl: null,
    instagramUrl: null,
    address: null,
    lat: null,
    lng: null,
    workTime: null,
    userLogin: null,
    status: PlaceStatusEnum.open,
    companyId: null,
    tags: [],
    image: null,
    placeStatusEnum: PlaceStatusEnum,

    placeTagList: [],
    placeImageList: [],
    placeLogoImageList: {
      logo_xs: null,
      logo_sm: null,
      logo_md: null,
      logo_lg: null
    },
    companyList: [],

    placeLogoImageTypeList: {
      logo_xs: 'logo XS (<768px)',
      logo_sm: 'logo SM (768-1024px)',
      logo_md: 'logo MD (1024-1360px)',
      logo_lg: 'logo LG (>1360px)'
    },

    mapObject: null,
    mapMarker: null,
    mapGeocoder: null,

    editorInit: {
      toolbar: 'undo redo | table | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent',
      menubar: '',
      plugins: 'table',
      forced_root_block: 'div',
      newline_behavior: 'invert',
      content_style: 'p { margin: 0; }',
      table_default_attributes: {
        border: '0',
        width: '350px'
      },
      table_default_styles: {
        border: '0',
        width: '350px'
      }
    }
  }),

  async mounted() {

    this.isEdit = this.$route.params?.id ? true : false;

    if (!await this.checkAdmin())
      return;

    this.setBreadcrumbs();

    await this.initMap();

    if (this.isEdit)
      await this.doPlaceAdminEditInit();
    else
      await this.doPlaceAdminAddInit();

    this.initTagSelect();
  },

  methods: {

    setPlaceLogoImageList(placeLogoImageList) {

      if (!placeLogoImageList)
        return;

      placeLogoImageList.forEach((placeLogoImageData) => {

        this.placeLogoImageList[placeLogoImageData.type] = placeLogoImageData;
      });
    },

    async doPlaceAdminEditInit() {

      let apiHolder = await this.sendApiGet('api/place/admin/edit/init/' + this.$route.params?.id);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeTagList)
        this.placeTagList = apiHolder.data.placeTagList;
      if (apiHolder.data?.placeTagIds)
        this.tags = apiHolder.data.placeTagIds;
      if (apiHolder.data?.placeImageList)
        this.placeImageList = apiHolder.data.placeImageList;
      if (apiHolder.data?.companyList)
        this.companyList = apiHolder.data.companyList;

      this.setPlaceLogoImageList(apiHolder.data?.placeLogoImageList);

      if (apiHolder.data?.placeData) {

        this.title = apiHolder.data.placeData?.title;
        this.text = this.decode(apiHolder.data.placeData?.text);
        this.price = this.getPrice(apiHolder.data.placeData?.price);
        this.address = apiHolder.data.placeData?.address;
        this.phone = apiHolder.data.placeData?.phone;
        this.site = apiHolder.data.placeData?.site;
        this.facebookUrl = apiHolder.data.placeData?.facebookUrl;
        this.instagramUrl = apiHolder.data.placeData?.instagramUrl;
        this.status = apiHolder.data.placeData?.status;
        this.workTime = this.decode(apiHolder.data.placeData?.workTime);
        this.companyId = apiHolder.data.placeData?.companyId;

        if (apiHolder.data.placeData?.lat)
          this.lat = apiHolder.data.placeData?.lat;
        if (apiHolder.data.placeData?.lng)
          this.lng = apiHolder.data.placeData?.lng;

        if (this.lat && this.lng) {

          const latLng = {
            lat: this.lat,
            lng: this.lng
          };

          this.setMapMarker(latLng);

          if (this.mapObject)
            this.mapObject.setCenter(latLng);
        }
      }

      this.userLogin = apiHolder.data?.userData?.email;
    },

    async doPlaceAdminAddInit() {

      let apiHolder = await this.sendApiGet('api/place/admin/add/init');

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeTagList)
        this.placeTagList = apiHolder.data.placeTagList;
      if (apiHolder.data?.placeImageList)
        this.placeImageList = apiHolder.data.placeImageList;
      if (apiHolder.data?.companyList)
        this.companyList = apiHolder.data.companyList;

      this.setPlaceLogoImageList(apiHolder.data?.placeLogoImageList);
    },

    async doPlaceAdminEdit() {

      let apiHolder = await this.sendApiPost('api/place/admin/edit/' + this.$route.params?.id, {
        title: this.title,
        text: this.formatTextInTags(this.text),
        price: this.price,
        address: this.address,
        phone: this.phone,
        site: this.site,
        facebookUrl: this.facebookUrl,
        instagramUrl: this.instagramUrl,
        lat: this.lat,
        lng: this.lng,
        workTime: this.formatTextInTags(this.workTime),
        userLogin: this.userLogin,
        tags: this.tags,
        status: this.status,
        companyId: this.companyId
      });

      if (!apiHolder.isSuccess())
        return;

      await this.redirect('/place/admin');
    },

    async doPlaceAdminAdd() {

      let apiHolder = await this.sendApiPost('api/place/admin/add', {
        title: this.title,
        text: this.formatTextInTags(this.text),
        price: this.price,
        address: this.address,
        phone: this.phone,
        site: this.site,
        facebookUrl: this.facebookUrl,
        instagramUrl: this.instagramUrl,
        lat: this.lat,
        lng: this.lng,
        workTime: this.formatTextInTags(this.workTime),
        userLogin: this.userLogin,
        tags: this.tags,
        status: this.status,
        companyId: this.companyId
      });

      if (!apiHolder.isSuccess())
        return;

      await this.redirect('/place/admin');
    },

    initTagSelect() {

      new Choices('#tags');
    },

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        ['Admin panel', '/admin'],
        ['Gym management', '/place/admin'],
        this.isEdit ? 'Edit gym' : 'Add gym'
      ]);
    },

    async doPlaceImageUpload(event) {

      let formData = new FormData();
      formData.append('image', event?.target?.files[0]);

      let apiHolder = await this.sendApiPost(this.$route.params?.id ? ('api/place/admin/image/add/' +
          this.$route.params?.id) : 'api/place/admin/image/add', formData, {isModalMessage: true});

      this.$refs.image.value = '';

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeImageData)
        this.placeImageList.push(apiHolder.data.placeImageData);
    },

    doPlaceImageSelect() {

      console.log(this.$refs.image);

      this.$refs.image.click();
    },

    async doPlaceImageDelete(imageId) {

      let apiHolder = await this.sendApiDelete('api/place/admin/image/delete/' + imageId, {isModalMessage: true});
      if (!apiHolder.isSuccess())
        return;

      this.placeImageList.forEach((placeImageData, index) => {

        if (placeImageData.id == imageId)
          this.placeImageList.splice(index, 1);

      });
    },

    async doPlaceLogoImageUpload(event, type) {

      let formData = new FormData();
      formData.append('image', event?.target?.files[0]);

      let route = 'api/place/admin/image/logo/add';
      if (this.$route.params?.id)
        route += '/' + this.$route.params?.id;
      route += '?type=' + type;

      let apiHolder = await this.sendApiPost(route, formData, {isModalMessage: true});

      this.$refs[type].value = '';

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeLogoImageData)
        this.placeLogoImageList[type] = apiHolder.data.placeLogoImageData;
    },

    doPlaceLogoImageSelect(type) {

      this.$refs[type][0].click();
    },

    async doPlaceLogoImageDelete(imageId, type) {

      let apiHolder = await this.sendApiDelete('api/place/admin/image/logo/delete/' + imageId, {isModalMessage: true});
      if (!apiHolder.isSuccess())
        return;

      this.placeLogoImageList[type] = null;
    },

    updateMapMarkerPosition() {

      if (!this.mapMarker)
        return;

      this.lat = this.mapMarker.getPosition().lat();
      this.lng = this.mapMarker.getPosition().lng();
    },

    setMapMarker(latLng) {

      if (!this.mapObject)
        return;

      if (!this.mapMarker) {

        this.mapMarker = new window.google.maps.Marker({
          position: latLng,
          map: this.mapObject,
          draggable: true
        });

        window.google.maps.event.addListener(this.mapMarker, 'dragend', () => {

          this.updateMapMarkerPosition();
        });

      } else {

        this.mapMarker.setPosition(latLng);
      }

      this.updateMapMarkerPosition();
    },

    async initMap() {

      const loader = new Loader({
        apiKey: process.env.VUE_APP_MAP_KEY,
        libraries: ['places']
      });

      await loader.load();

      this.mapObject = new window.google.maps.Map(document.getElementById('map'), {
        center: this.$store.state.mapCenter,
        zoom: this.$store.state.mapZoom,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      });

      window.google.maps.event.addListener(this.mapObject, 'click', (event) => {

        this.setMapMarker(event.latLng);
      });

      const input = document.getElementById('map-search');
      const searchBox = new window.google.maps.places.SearchBox(input);
      this.mapObject.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

      this.mapObject.addListener('bounds_changed', () => {

        searchBox.setBounds(this.mapObject.getBounds());
      });

      searchBox.addListener('places_changed', () => {

        const places = searchBox.getPlaces();
        if (places.length == 0)
          return;

        const bounds = new window.google.maps.LatLngBounds();

        places.forEach((place) => {

          if (!place.geometry || !place.geometry.location)
            return;

          this.setMapMarker(place.geometry.location);

          if (place.geometry.viewport)
            bounds.union(place.geometry.viewport);
          else
            bounds.extend(place.geometry.location);
        });

        this.mapObject.fitBounds(bounds);
      });
    }
  },

}
</script>

<style scoped>

#map {
  height: 350px;
}

.image-item {
  display: inline-block;
  padding-right: 5px;
}

.image-item img {
  max-width: 200px;
}

</style>